import styled from "styled-components"
import NextLink from "next/link"

const Link = styled(NextLink)`
  color: ${props => props.theme.colors.text};
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }

  &:active {
    color: ${props => props.theme.colors.text};
  }
`

export default Link
