// React components
import DownloadItem from '../DownloadItem'

// Styled Components
import { DownloadListWrapper, Container } from './DownloadList.styles'
import { Title } from '../../styled-components/shared/common'
import Spacer from '../../styled-components/shared/Spacer'

// Types
import { DownloadListProps } from './DownloadList.types'

const DownloadList = ({
  files,
  id,
  title,
  noTopSpacing = false,
}: DownloadListProps) => {
  return (
    <DownloadListWrapper noTopSpacing={noTopSpacing} id={id}>
      <Title type='red'>{title ? title : 'Ke stažení'}</Title>
      <Spacer size={18} axis='vertical' when={{ lg: 30 }} />
      <Container>
        {files.data.map((file, i) => {
          return (
            <DownloadItem
              key={i}
              fileName={file.attributes.name}
              href={file.attributes.url}
            />
          )
        })}
      </Container>
    </DownloadListWrapper>
  )
}
export default DownloadList
