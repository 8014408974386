import styled, { css } from 'styled-components'

// Types
import { DownloadListWrapperProps } from './DownloadList.types'

export const DownloadListWrapper = styled.div<DownloadListWrapperProps>`
  margin-top: -90px;
  padding-top: 90px;

  @media (${({ theme }) => theme.mediaQueries.lg}) {
    margin-top: -120px;
    padding-top: 120px;
  }

  ${({ noTopSpacing, theme }) =>
    noTopSpacing &&
    css`
      margin-top: 0;
      padding-top: 0;

      @media (${theme.mediaQueries.lg}) {
        margin-top: -0px;
        padding-top: 0px;
      }
    `}
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`
