// React components
import Layout from '../components/Layout'
import NotFound from '../components/pages/NotFound'
import Seo from '../components/Seo'

// Static API data
import { provideStaticApiData } from '../utils/api'

import { GET_SERVICE_LIST } from '../utils/graphql-queries'

// COLORS
import { COLORS } from '../utils/constants'

// Types
import { Api } from '../types/api'

const NotFoundPage = ({ serviceList, career }: Api.StaticData) => {
  return (
    <>
      <Seo title='Stránka nenalezena' />
      <Layout
        serviceList={serviceList}
        background={COLORS.grey80}
        career={career}
      >
        <NotFound />
      </Layout>
    </>
  )
}

export const getStaticProps = provideStaticApiData({ query: GET_SERVICE_LIST })

export default NotFoundPage
