// Next.js
import Link from '../../Link'

// React components
import Button from '../../Button'

// Styled Components
import { NotFoundWrapper, Container, ButtonWrapper } from './NotFound.styles'
import {
  MainContainer,
  PageTitle,
  LightText,
} from '../../../styled-components/shared/common'
import Spacer from '../../../styled-components/shared/Spacer'
import useDictionaryLine from '../../../hooks/useDictionary'
import dictionary from '../../../utils/dictionary'

const NotFound = () => {
  const page = useDictionaryLine(dictionary.page404.page)
  const notFound = useDictionaryLine(dictionary.page404.notFound)
  const line1 = useDictionaryLine(dictionary.page404.line1)
  const line2 = useDictionaryLine(dictionary.page404.line2)
  const line3 = useDictionaryLine(dictionary.page404.line3)
  const back = useDictionaryLine(dictionary.page404.back)
  return (
    <NotFoundWrapper>
      <Container>
        <MainContainer>
          <Spacer size={120} when={{ lg: 240 }} axis='vertical' />
          <PageTitle>
            <LightText>{page}</LightText> {notFound}!
          </PageTitle>
          <Spacer size={18} when={{ lg: 48 }} axis='vertical' />
          <p>
            {line1}
            <br /> <br />
            {line2} <br /> <br />
            {line3}
          </p>
          <Spacer size={30} when={{ lg: 72 }} axis='vertical' />
          <ButtonWrapper>
            <Link href='/' passHref legacyBehavior>
              <Button variant='contained' link={true}>
                {back}
              </Button>
            </Link>
          </ButtonWrapper>
          <Spacer size={60} when={{ lg: 120 }} axis='vertical' />
        </MainContainer>
      </Container>
    </NotFoundWrapper>
  )
}
export default NotFound
