import styled from 'styled-components'

export const EstablishmentWrapper = styled.div``

export const Address = styled.p``

export const MapContainer = styled.div`
  height: 244px;
  margin-left: -36px;
  margin-right: -36px;

  @media (${({ theme }) => theme.mediaQueries.lg}) {
    height: 600px;
    margin-left: initial;
    margin-right: initial;
  }

  @media (${({ theme }) => theme.mediaQueries.xxl}) {
    height: 941px;
  }
`

export const MainContentContainer = styled.div`
  max-width: 1190px;
`

export const ContentContainer = styled.div`
  max-width: 1920px;
  margin: 0 auto;

  @media (${({ theme }) => theme.mediaQueries.lg}) {
    display: grid;
    grid-template-columns: 360px auto;
  }

  @media (${({ theme }) => theme.mediaQueries.xxl}) {
    gap: 30px;
    grid-template-columns: 538px auto;
  }
`

export const LocationsWrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 1140px;
`
