// React components (svg)
import DownloadIcon from '../svgs/icons/DownloadIcon'

// Styled Components
import {
  DownloadItemWrapper,
  FileName,
  IconWrapper,
} from './DownloadItem.styles'

// Types
import { DownloadItemProps } from './DownloadItem.types'

const DownloadItem = ({ fileName, href }: DownloadItemProps) => {
  return (
    <DownloadItemWrapper
      title={fileName}
      aria-label='Stáhnout'
      href={href}
      download
      rel='noopener noreferrer'
      target='_blank'
    >
      <FileName>{fileName}</FileName>
      <IconWrapper>
        <DownloadIcon />
      </IconWrapper>
    </DownloadItemWrapper>
  )
}
export default DownloadItem
