// Next.js
import { useRouter } from '../../../hooks/router'

// React
import { useCallback, useEffect } from 'react'

// HTML React Parser
import parse from 'html-react-parser'

// Artsy Fresnel
import { Media, MediaContextProvider } from '../../../utils/media'

// React components
import SocialIconsBar from '../../SocialIconsBar'
import Contacts from '../../Contacts'
import RegionSelect from '../../RegionSelect'
import DownloadList from '../../DownloadList'
import Breadcrumb from '../../Breadcrumb'
import Crumb from '../../Breadcrumb/Crumb'
import Map from '../../Map'
import Service from '../../Service'

// React components (svg)
import FacebookIcon from '../../svgs/icons/FacebookIcon'
import InstagramIcon from '../../svgs/icons/InstagramIcon'
import ShareIcon from '../../svgs/icons/ShareIcon'

// Styled Components
import {
  EstablishmentWrapper,
  Address,
  MapContainer,
  MainContentContainer,
  ContentContainer,
  LocationsWrapper,
} from './Establishment.styles'
import {
  PageTitle,
  Title,
  MainContainer,
  ContentWrapper,
} from '../../../styled-components/shared/common'
import { LightText, Divider } from '../../../styled-components/shared/common'
import Spacer from '../../../styled-components/shared/Spacer'

// Types
import { EstablishmentProps } from './Establishment.types'
import useDictionaryLine from '../../../hooks/useDictionary'
import dictionary from '../../../utils/dictionary'
import { splitTextBy } from '../../../utils/dom'
import EstablishmentCards from '../../EstablishmentCards'
import { useState } from 'react'
import { ButtonContainer } from '../../ContactForm/ContactForm.styles'
import Button from '../../Button'

const Establishment = ({ location, regionLocations }: EstablishmentProps) => {
  const [page, setPage] = useState(1)
  const lookUpLocale = useDictionaryLine(dictionary.searchLocation)
  const subsidiaryLocale = useDictionaryLine(
    dictionary.contact.subsidiaryLocationsTitle,
  )
  const locationsLocale = useDictionaryLine(dictionary.mainNavigation.locations)
  const loadMoreLocale = useDictionaryLine(dictionary.contact.loadMore)
  const purchaseBoxLocale = useDictionaryLine(
    dictionary.contact.purchaseBoxTitle,
  )
  const { asPath } = useRouter()

  const { address, name, contacts, purchaseBox, files, openingHours } =
    location?.attributes
  const lat = parseFloat(address?.GpsLatitude || '')
  const lng = parseFloat(address?.GpsLongitude || '')
  const regionLocationsToDisplay =
    regionLocations &&
    regionLocations.filter(r => r.id !== location.id).slice(0, page * 4)

  const resetWindowScrollPosition = useCallback(
    () =>
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'instant' as any,
      }),
    [],
  )

  useEffect(() => {
    if (window !== undefined) {
      setTimeout(() => {
        resetWindowScrollPosition()
      }, 0)
    }
  }, [asPath, location.id])

  return (
    <EstablishmentWrapper>
      <MainContainer>
        <MediaContextProvider disableDynamicMediaQueries>
          <Media greaterThanOrEqual='lg'>
            <Spacer size={30} />
            <Breadcrumb>
              <Crumb href='/kontakt'>{locationsLocale}</Crumb>
              <Crumb href={asPath} isCurrentPage={true}>
                {name}
              </Crumb>
            </Breadcrumb>
          </Media>
        </MediaContextProvider>
      </MainContainer>
      <ContentContainer>
        <MediaContextProvider disableDynamicMediaQueries>
          <Media greaterThanOrEqual='lg'>
            <MapContainer>
              <Spacer size={60} axis='vertical' when={{ lg: 90 }} />
              <Map title={name || ''} lat={lat} lng={lng} />
            </MapContainer>
          </Media>
        </MediaContextProvider>
        <MainContentContainer>
          <MainContainer>
            <Spacer size={60} axis='vertical' when={{ lg: 90 }} />
            <PageTitle>{name}</PageTitle>
            <Spacer size={30} axis='vertical' when={{ lg: 60 }} />
            <SocialIconsBar
              icons={[
                {
                  icon: <FacebookIcon />,
                  href: location.attributes.social?.facebook || '',
                },
                {
                  icon: <InstagramIcon />,
                  href: location.attributes.social?.instagram || '',
                },
                {
                  icon: <ShareIcon />,
                  href: location.attributes.social?.linkedin || '',
                },
              ].filter(icon => icon.href)}
            />
            <MediaContextProvider disableDynamicMediaQueries>
              <Media lessThan='lg'>
                <Spacer size={24} axis='vertical' />
                <MapContainer>
                  <Map title={name || ''} lat={lat} lng={lng} />
                </MapContainer>
              </Media>
            </MediaContextProvider>
            <Spacer size={30} axis='vertical' when={{ lg: 60 }} />
            <Address>{`${address?.Address}${
              address?.AddressName && address?.AddressName !== ' '
                ? `, ${address?.AddressName}`
                : ''
            }`}</Address>
            {openingHours && (
              <>
                <Spacer size={54} axis='vertical' when={{ lg: 60 }} />
                <Title type='red'>
                  <span>Otevírací doba</span> <br /> {name ? name : ''}
                </Title>
                <Spacer size={24} axis='vertical' when={{ lg: 30 }} />
                <p
                  dangerouslySetInnerHTML={{
                    __html: location.attributes.openingHours || '',
                  }}
                />
              </>
            )}
            {contacts && contacts.length > 0 && (
              <>
                <Spacer size={60} axis='vertical' />
                <Contacts contacts={contacts} />
              </>
            )}
            <Spacer size={60} axis='vertical' />
            {location.attributes.taby && location.attributes.taby.length > 0 && (
              <>
                <Spacer size={48} axis='vertical' />
                {location.attributes.taby.map((tab, i) => {
                  return (
                    <Service
                      key={i}
                      id={`${
                        tab.Title
                          ? tab.Title.replace(/&nbsp;/g, ' ')
                              .normalize('NFD')
                              .replace(/[\u0300-\u036f]/g, '')
                              .replace(/[^a-z0-9- ]/gi, '')
                              .split(' ')
                              .join('-')
                              .toLowerCase()
                          : ''
                      }`}
                      header={tab.Title || ''}
                      open={false}
                    >
                      <ContentWrapper>
                        {parse(tab.Content || '')}
                      </ContentWrapper>
                    </Service>
                  )
                })}
              </>
            )}
            {purchaseBox && (
              <>
                {/* <Spacer size={60} axis='vertical' />
                <Title type='red'>{purchaseBoxLocale}</Title>
                <Spacer size={24} axis='vertical' when={{ lg: 30 }} />
                <ContentWrapper>
                  {parse(purchaseBox ? purchaseBox : '')}
                </ContentWrapper> */}
                <Service
                  id={`${purchaseBoxLocale
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                    .split(' ')
                    .join('-')
                    .toLowerCase()}`}
                  header={purchaseBoxLocale}
                  open={true}
                >
                  <ContentWrapper>
                    {parse(purchaseBox ? purchaseBox : '')}
                  </ContentWrapper>
                </Service>
              </>
            )}
            {files && files.data.length > 0 && (
              <>
                <Spacer size={54} axis='vertical' when={{ lg: 60 }} />
                <DownloadList files={files} />
              </>
            )}
          </MainContainer>
        </MainContentContainer>
      </ContentContainer>
      <MainContainer alignItems='center'>
        <Spacer size={60} axis='vertical' when={{ lg: 120 }} />
        <Divider maxWidth='667px' center={true} />
        <Spacer size={60} axis='vertical' />
        {location.attributes.provozovny.data?.length > 0 && (
          <MainContainer alignItems='center'>
            <Title align='center'>
              {splitTextBy({
                by: '|',
                removeSplitter: true,
                line: subsidiaryLocale,
                Right: LightText,
              })}
            </Title>
            <Spacer size={60} axis='vertical' when={{ lg: 120 }} />
            <LocationsWrapper>
              <EstablishmentCards
                locations={location.attributes.provozovny.data}
              />
            </LocationsWrapper>
            <Spacer size={60} axis='vertical' when={{ lg: 120 }} />
          </MainContainer>
        )}

        <Title align='center'>
          {splitTextBy({ line: lookUpLocale, Right: LightText })}
        </Title>
        <Spacer size={24} axis='vertical' when={{ lg: 60 }} />
        <RegionSelect
          up={true}
          darkBackground={false}
          defaultValue={location.attributes.region}
        />
        <Spacer size={60} axis='vertical' when={{ lg: 120 }} />
      </MainContainer>
      {regionLocationsToDisplay && (
        <MainContainer alignItems='center'>
          <LocationsWrapper>
            <EstablishmentCards locations={regionLocationsToDisplay} />
            <Spacer size={42} when={{ lg: 60 }} axis='vertical' />
            <ButtonContainer>
              <Button
                variant='contained'
                arrow={true}
                onClick={() => setPage(page => page + 1)}
              >
                {loadMoreLocale}
              </Button>
            </ButtonContainer>
          </LocationsWrapper>
          <Spacer size={60} axis='vertical' when={{ lg: 120 }} />
        </MainContainer>
      )}
    </EstablishmentWrapper>
  )
}
export default Establishment
