import styled from 'styled-components'

export const NotFoundWrapper = styled.div`
  max-width: 1920px;
  margin: 0 auto;
  height: 100%;
  background: url('/images/not-found-bg-mobile.svg') center top 30px / contain
    no-repeat;

  @media (${({ theme }) => theme.mediaQueries.xl}) {
    background: url('/images/not-found-bg.svg') left 120px center no-repeat;
  }
`

export const Container = styled.div`
  @media (${({ theme }) => theme.mediaQueries.lg}) {
    max-width: 930px;
    margin: 0 auto;
  }
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`
