const DownloadIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='35.561'
      height='27.658'
      data-name='Component 49 – 2'
      viewBox='0 0 35.561 27.658'
    >
      <g
        fill='none'
        stroke='#d90017'
        strokeWidth='1'
        data-name='Rectangle 475'
        transform='rotate(-90 13.829 13.829)'
      >
        <rect width='21.179' height='35.561' stroke='none' rx='10.589'></rect>
        <rect width='20.179' height='34.561' x='0.5' y='0.5' rx='10.089'></rect>
      </g>
      <g
        fill='none'
        stroke='#d90017'
        strokeMiterlimit='10'
        strokeWidth='1'
        data-name='Group 46'
        transform='rotate(90 10.765 10.766)'
      >
        <path
          d='M19.914 0L0 0'
          data-name='Line 54'
          transform='translate(0 3.39)'
        ></path>
        <path d='M16.155 0l3.39 3.39-3.39 3.39' data-name='Path 29'></path>
      </g>
    </svg>
  )
}
export default DownloadIcon
