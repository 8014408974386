// React
import { useState, useEffect } from 'react'
import { useRouter } from '../../hooks/router'

// Artsy Fresnel
import { Media, MediaContextProvider } from '../../utils/media'

// Framer Motion
import { AnimatePresence } from 'framer-motion'

// React components (svg)
import ArrowCircleIcon from '../svgs/icons/ArrowCircleIcon'

// Styled Components
import {
  ServiceWrapper,
  Header,
  CircleArrowIconWrapper,
  Content,
  ContentWrapper,
} from './Service.styles'
import { Title, NormalText } from '../../styled-components/shared/common'

// Types
import { ServiceProps } from './Service.types'

const Service = ({
  header,
  headerTail,
  children,
  id,
  open = null,
}: ServiceProps) => {
  const router = useRouter()
  const hashtag = router.asPath?.split?.('#')?.[1]
  const [isOpen, setIsOpen] = useState(open === null ? hashtag === id : open)

  // Hashtag can be changed when not leaving page
  useEffect(() => {
    if (open === null) {
      if (hashtag === id) {
        setIsOpen(true)
      }
    }
  }, [id, hashtag, setIsOpen])

  return (
    <ServiceWrapper id={id}>
      <Header
        onClick={() => {
          setIsOpen(prev => !prev)
        }}
      >
        <MediaContextProvider disableDynamicMediaQueries>
          <Media lessThan='lg'>
            <CircleArrowIconWrapper
              animate={{
                rotate: isOpen ? '0deg' : '180deg',
                transition: { duration: 0.3, ease: 'easeInOut' },
              }}
            >
              <ArrowCircleIcon />
            </CircleArrowIconWrapper>
            <Title type='red'>
              {header}{' '}
              {headerTail && <NormalText>{`- ${headerTail}`}</NormalText>}
            </Title>
          </Media>
        </MediaContextProvider>
        <MediaContextProvider disableDynamicMediaQueries>
          <Media greaterThanOrEqual='lg'>
            <Title type='red'>
              {header}{' '}
              {headerTail && <NormalText>{`- ${headerTail}`}</NormalText>}
            </Title>
            <CircleArrowIconWrapper
              animate={{
                rotate: isOpen ? '0deg' : '180deg',
                transition: { duration: 0.3, ease: 'easeInOut' },
              }}
            >
              <ArrowCircleIcon />
            </CircleArrowIconWrapper>
          </Media>
        </MediaContextProvider>
      </Header>
      <AnimatePresence>
        {isOpen && (
          <ContentWrapper
            initial={{ opacity: 0, height: 0 }}
            animate={{
              opacity: 1,
              height: 'auto',
              transition: { duration: 0.3, ease: 'easeInOut' },
            }}
            exit={{
              opacity: 0,
              height: 0,
              transition: { duration: 0.3, ease: 'easeInOut' },
            }}
          >
            <Content>{children}</Content>
          </ContentWrapper>
        )}
      </AnimatePresence>
    </ServiceWrapper>
  )
}
export default Service
