// Next.js
import Head from 'next/head'

// Types (Next.js)
import type { NextPage } from 'next'

// React components
import Layout from '../../../components/Layout'
import Establishment from '../../../components/pages/Establishment'
import Seo from '../../../components/Seo'
import PreviewBar from '../../../components/PreviewBar'

// Static API data
import { provideStaticApiData } from '../../../utils/api'

// GraphQL queries
import {
  GET_CONTACT_SLUGS_ONLY,
  GET_LOCATIONS_BY_REGION,
  GET_LOCATIONS_BY_SLUG,
} from '../../../utils/graphql-queries'

// Types
import { Api } from '../../../types/api'
import NotFoundPage from '../../404'
import client from '../../../utils/apollo-client'

type Props = Api.StaticData & {
  data?: {
    locations: {
      data: Api.Location[]
    }
    regionLocations: {
      data: Api.Location[]
    }
    contact: {
      data: Api.ContactData
    }
  }
  preview?: boolean
}

const Page: NextPage = ({ data, serviceList, preview }: Props) => {
  const location = data?.locations.data[0]

  if (!data) {
    return null
  }

  if (!location) {
    return <NotFoundPage />
  }

  return (
    <>
      <Seo
        title={location.attributes.seo?.title || location.attributes.name}
        description={location.attributes.seo?.description}
        robots={location.attributes.seo?.robots}
      />
      {preview ? <PreviewBar /> : null}
      <Layout serviceList={serviceList} gradientBg={true}>
        <Establishment
          location={location}
          regionLocations={data.regionLocations.data}
        />
      </Layout>
    </>
  )
}

export async function getStaticPaths() {
  if (process.env.NEXT_IS_CAREER) {
    return {
      paths: [],
      fallback: true,
    }
  }
  const { data } = await client.query({
    query: GET_CONTACT_SLUGS_ONLY(),
  })
  return {
    paths: data?.locations.data.map((location: any) => {
      return location.attributes.locale === 'en'
        ? `/en/kontakt/${location.attributes.region
            .toLowerCase()
            .replaceAll('_', '-')}/${location.attributes.slug}`
        : `/kontakt/${location.attributes.region
            .toLowerCase()
            .replaceAll('_', '-')}/${location.attributes.slug}`
    }),
    fallback: true,
  }
}

export const getStaticProps = async (context: any) => {
  const preview = context.preview || null

  if (context.locale === 'en') {
    return {
      notFound: true,
    }
  }

  const dataBySlug = await provideStaticApiData({
    query: GET_LOCATIONS_BY_SLUG,
  })({ ...context, publicationState: preview ? 'PREVIEW' : 'LIVE' })

  const allData = await provideStaticApiData({
    query: GET_LOCATIONS_BY_REGION,
  })(context)

  return {
    ...dataBySlug,
    props: {
      ...dataBySlug.props,
      data: {
        locations: dataBySlug?.props?.data.locations,
        regionLocations: allData?.props?.data.locations,
      },
      preview: preview,
    },
  }
}

export default Page
