import styled from 'styled-components'

// Framer Motion
import { motion } from 'framer-motion'

export const ServiceWrapper = styled.div`
  padding-bottom: 60px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.service.border};
  margin-bottom: 60px;
  padding-top: 90px;
  margin-top: -90px;

  @media (${({ theme }) => theme.mediaQueries.lg}) {
    padding-top: 120px;
    margin-top: -120px;
  }
`

export const Header = styled.div`
  cursor: pointer;
  display: flex;
  position: relative;
  z-index: 3;
  align-items: flex-start;
  gap: 6px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.service.header};

  svg {
    g {
      g {
        transition: fill 0.3s;

        path {
          transition: stroke 0.3s;
        }
      }
    }
  }

  &:hover {
    svg {
      g {
         {
          &:last-of-type {
            path {
              stroke: ${({ theme }) => theme.colors.contact.icon.arrowHover};
            }
          }

          &:first-of-type {
            fill: ${({ theme }) => theme.colors.contact.icon.backgroundHover};
          }
        }
      }
    }
  }

  @media (${({ theme }) => theme.mediaQueries.md}) {
    gap: 12px;
  }
`

export const Content = styled.div`
  padding: 24px 0;
`

export const CircleArrowIconWrapper = motion(styled.div`
  flex-shrink: 0;
`)

export const ContentWrapper = motion(styled.div``)
