import styled from 'styled-components'

export const FileName = styled.div`
  color: ${({ theme }) => theme.colors.downloadItem.text};
  text-decoration: none;
`

export const IconWrapper = styled.div`
  @media (${({ theme }) => theme.mediaQueries.lg}) {
    svg {
      width: 48px;
      height: 37px;

      g  {
        transition: all 0.3s;
      }
    }
  }
`

export const DownloadItemWrapper = styled.a`
  display: flex;
  gap: 6px;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.downloadItem.border};
  text-decoration: none;

  &:hover {
    text-decoration: none;
    ${FileName} {
      color: ${({ theme }) => theme.colors.downloadItem.textHover};
    }

    ${IconWrapper} {
      svg {
        g {
          &:last-of-type {
            stroke: ${({ theme }) => theme.colors.downloadItem.icon.arrowHover};
          }

          &:first-of-type {
            fill: ${({ theme }) =>
              theme.colors.downloadItem.icon.backgroundHover};
          }
        }
      }
    }
  }
`
