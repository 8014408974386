// Styled Components
import StyledPreviewBar from './StyledPreviewBar'
import Text from './Text'
import Link from './Link'

const PreviewBar = () => {
  return (
    <StyledPreviewBar>
      <Text>
        <span>Preview mode is on,</span>{' '}
        <span>
          <Link href='/api/exit-preview'>turn off</Link>
        </span>
      </Text>
    </StyledPreviewBar>
  )
}
export default PreviewBar
