
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/kontakt/[region]/[detailUrl]",
      function () {
        return require("private-next-pages/kontakt/[region]/[detailUrl].tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/kontakt/[region]/[detailUrl]"])
      });
    }
  